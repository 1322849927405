import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MODAL_ANIMATION_TIME } from '../../../utils/helpers/constants';
import {
  StyledSearchContainer,
  StyledSearchInput,
  StyledSearchResetButton,
  StyledSearchLabel,
  IconContainer,
  SearchIconContainer
} from './styled';
import Icon from '../../Icon';
import EyeIcon from '../../../public/static/images/eye.svg';
import CloseEyeIcon from '../../../public/static/images/closed-eye.svg';
import { InputSize } from './types';
import { VISIBLE_VALUE_ICON_SIZE } from './constants';
import { tagStyles } from '../Typography/styles';
import { useToggleInputValueVisible } from './hooks/useToggleInputValueVisible';
import { useHideCaretUnderHeader } from './hooks/useHideCaretUnderHeader';

export const NewInput = ({
  id,
  stat,
  value,
  name = 'search',
  refEl,
  onKeyDown,
  placeholder,
  onBlurHandler,
  onResetHandler,
  onFocusHandler,
  onChangeHandler,
  isPin = false,
  autoComplete = 'off',
  inputMode = 'text',
  isAutoFocus = false,
  isShowReset = false,
  isInsideModalWithAnimation = false,
  isLabelAnimated = false,
  isShowSearch = true,
  isValueHidden = false,
  size = InputSize.Medium,
  cypress = 'search-input',
  inputTagStyle = tagStyles.body_1,
  headerRef,
  containerRef
}) => {
  const inputRef = useRef();
  const { isValueVisible, toggleValueVisibility } =
    useToggleInputValueVisible(inputRef);
  const [isFocused, setIsFocused] = useState(false);

  const { resetInputCaretStyle } = useHideCaretUnderHeader(
    headerRef,
    inputRef,
    isFocused,
    containerRef
  );

  const handleFocus = () => {
    setIsFocused(true);
    inputRef.current.focus();
    resetInputCaretStyle();
  };

  useEffect(() => {
    if (isAutoFocus && refEl?.current) {
      if (isInsideModalWithAnimation) {
        setTimeout(() => refEl.current.focus(), MODAL_ANIMATION_TIME);
      } else handleFocus();
    }
  }, [isAutoFocus]);

  const handleBlur = useCallback(() => {
    if (!inputRef?.current?.value) setIsFocused(false);
  }, [inputRef]);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement?.addEventListener('focus', handleFocus);
      inputElement?.addEventListener('blur', handleBlur);

      return () => {
        inputElement?.removeEventListener('focus', handleFocus);
        inputElement?.removeEventListener('blur', handleBlur);
      };
    }
  }, []);

  const onAnimationStart = useCallback(() => setIsFocused(true), []);

  return (
    <StyledSearchContainer
      data-stat={stat}
      isPin={isPin}
      isActive={isFocused || !!value}
      size={size}
    >
      {isShowSearch && (
        <SearchIconContainer {...{ size, isShowSearch, isPin }} />
      )}
      <StyledSearchInput
        id={id}
        size={size}
        isShowSearch={isShowSearch}
        isShowReset={isShowReset}
        $inputTagStyle={inputTagStyle}
        isValueHidden={isValueHidden && value}
        ref={refEl || inputRef}
        type={isValueHidden && !isValueVisible ? 'password' : 'search'}
        name={name}
        value={value}
        tabIndex={-1}
        autoComplete={autoComplete}
        onKeyDown={onKeyDown}
        inputMode={inputMode}
        data-cy={cypress}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onAnimationStart={onAnimationStart}
        placeholder={placeholder}
        onChange={onChangeHandler}
        pattern={inputMode === 'numeric' ? '[0-9]*' : null}
        isLabelAnimated={isLabelAnimated}
      />
      {isLabelAnimated && <StyledSearchLabel>{placeholder}</StyledSearchLabel>}
      <IconContainer size={size} position="right">
        {isValueHidden && value && (
          <Icon
            color="transparent"
            indent={false}
            width={VISIBLE_VALUE_ICON_SIZE}
            height={18}
            IconComponent={isValueVisible ? EyeIcon : CloseEyeIcon}
            onClick={toggleValueVisibility}
            dataCypress={`${isValueVisible ? 'hide' : 'show'}-input-value-icon`}
          />
        )}
        {isShowReset && (
          <StyledSearchResetButton
            role="button"
            tabIndex={-1}
            onClick={onResetHandler}
            onKeyPress={onResetHandler}
            data-cy="input-reset-button"
          />
        )}
      </IconContainer>
    </StyledSearchContainer>
  );
};

NewInput.propTypes = {
  value: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  isPin: PropTypes.bool,
  stat: PropTypes.string,
  refEl: PropTypes.object,
  isAutoFocus: PropTypes.bool,
  isShowReset: PropTypes.bool,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  onResetHandler: PropTypes.func,
  isInsideModalWithAnimation: PropTypes.bool,
  inputMode: PropTypes.oneOf(['text', 'numeric']),
  isLabelAnimated: PropTypes.bool,
  autoComplete: PropTypes.string,
  inputTagStyle: PropTypes.array,
  isShowSearch: PropTypes.bool,
  isValueHidden: PropTypes.bool,
  size: PropTypes.oneOf([InputSize.Medium, InputSize.Large]),
  cypress: PropTypes.string,
  headerRef: PropTypes.object,
  containerRef: PropTypes.object
};
