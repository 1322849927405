import React from 'react';
import PropTypes from 'prop-types';
import { convertArrayToObject } from '../../../utils/helpers/convertArrayToObject';
import { NewInput } from './NewInput';
import { TextField } from './TextField';
import { NumberField } from './NumberField';
import { TextAreaField } from './TextAreaField';
import { MaskedPhoneInput } from './MaskedPhoneInput';
import { ErrorField } from './ErrorField';

const inputTypes = [
  'text',
  'range',
  'phone',
  'search',
  'boldText',
  'description'
];

export const InputTypeMap = convertArrayToObject(inputTypes);

/* eslint-disable */
const Components = {
  [InputTypeMap.text]: props => (
    <TextField
      styleType="default"
      stat={props.stat}
      name={props.name}
      icon={props.icon}
      value={props.value}
      format={props.format}
      isFloat={props.isFloat}
      onClick={props.onClick}
      disabled={props.disabled}
      inputMode={props.inputMode}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      onChange={props.onChange}
      type={props.type}
      formValues={props.formValues}
      isValueHidden={props.isValueHidden}
      isEnabledAutoSelectionRange={props.isEnabledAutoSelectionRange}
      containerRef={props.containerRef}
      headerRef={props.headerRef}
    />
  ),
  [InputTypeMap.boldText]: props => (
    <TextField
      styleType="bold"
      stat={props.stat}
      name={props.name}
      icon={props.icon}
      value={props.value}
      format={props.format}
      isFloat={props.isFloat}
      onClick={props.onClick}
      disabled={props.disabled}
      inputMode={props.inputMode}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      onChange={props.onChange}
      type={props.type}
      formValues={props.formValues}
      isValueHidden={props.isValueHidden}
      containerRef={props.containerRef}
      headerRef={props.headerRef}
      isEnabledAutoSelectionRange={props.isEnabledAutoSelectionRange}
    />
  ),
  [InputTypeMap.search]: props => (
    <NewInput
      id={props.id}
      stat={props.stat}
      refEl={props.refEl}
      isPin={props.isPin}
      value={props.value}
      onKeyDown={props.onKeyDown}
      inputMode={props.inputMode}
      isAutoFocus={props.isAutoFocus}
      placeholder={props.placeholder}
      isShowReset={props.isShowReset}
      onBlurHandler={props.onBlurHandler}
      onResetHandler={props.onResetHandler}
      onFocusHandler={props.onFocusHandler}
      onChangeHandler={props.onChangeHandler}
      isInsideModalWithAnimation={props.isInsideModalWithAnimation}
      isShowSearch={props.isShowSearch}
      isLabelAnimated={props.isLabelAnimated}
      isValueHidden={props.isValueHidden}
      isEnabledAutoSelectionRange={props.isEnabledAutoSelectionRange}
      containerRef={props.containerRef}
      headerRef={props.headerRef}
    />
  ),
  [InputTypeMap.range]: props => (
    <NumberField
      id={props.id}
      ref={props.refEl}
      name={props.name}
      stat={props.stat}
      value={props.value}
      onReset={props.onReset}
      onChange={props.onChange}
      placeholder={props.placeholder}
      isFullWidth={props.isFullWidth}
      onBlurHandler={props.onBlurHandler}
      onFocusHandler={props.onFocusHandler}
      floatingPlaceholder={props.floatingPlaceholder}
      isEnabledAutoSelectionRange={props.isEnabledAutoSelectionRange}
      containerRef={props.containerRef}
      headerRef={props.headerRef}
      isCustomInput={props.isCustomInput}
    />
  ),
  [InputTypeMap.phone]: props => (
    <MaskedPhoneInput
      icon={props.icon}
      name={props.name}
      onClick={props.onClick}
      disabled={props.disabled}
      placeholder={props.placeholder}
      isEnabledAutoSelectionRange={props.isEnabledAutoSelectionRange}
      containerRef={props.containerRef}
      headerRef={props.headerRef}
    />
  ),
  [InputTypeMap.description]: props => (
    <TextAreaField
      ref={props.refEl}
      stat={props.stat}
      name={props.name}
      format={props.format}
      onClick={props.onClick}
      inputMode={props.inputMode}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      isEnabledAutoSelectionRange={props.isEnabledAutoSelectionRange}
    />
  )
};
/* eslint-enable */

export const Input = ({ type, hasErrorField, errorConfig, ...props }) => {
  const Component = Components[type];
  return (
    <>
      <Component {...props} />
      {hasErrorField && (
        <ErrorField
          field={props.name}
          margin={errorConfig?.margin}
          divider={errorConfig?.divider}
          setError={errorConfig?.setError}
          cypress={`${props.name}-field-error`}
        />
      )}
    </>
  );
};

Input.propTypes = {
  errorConfig: PropTypes.object,
  hasErrorField: PropTypes.bool,
  type: PropTypes.oneOf(inputTypes).isRequired,
  id: PropTypes.string,
  icon: PropTypes.func,
  name: PropTypes.string,
  stat: PropTypes.string,
  format: PropTypes.func,
  refEl: PropTypes.object,
  isFloat: PropTypes.bool,
  onReset: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  inputMode: PropTypes.oneOf(['text', 'numeric']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // only range type
  isFullWidth: PropTypes.bool,
  floatingPlaceholder: PropTypes.string,
  // only search type
  isPin: PropTypes.bool,
  onKeyDown: PropTypes.func,
  isShowReset: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  onResetHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  isInsideModalWithAnimation: PropTypes.bool,
  isShowSearch: PropTypes.bool,
  isLabelAnimated: PropTypes.bool,
  isValueHidden: PropTypes.bool,
  headerRef: PropTypes.object,
  containerRef: PropTypes.object
};
